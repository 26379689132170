import apps from "./apps";

const routes = [
    {
        path: '/',
        component: () => import('layouts/Default.vue'),
        children: [
            {path: '', component: () => import('pages/IndexPage.vue')},
            {path: 'index', component: () => import('pages/IndexPage.vue')},
            {path: 'apps', component: () => import('pages/AppsPage.vue')},
            {path: 'configs', component: () => import('pages/ConfigsPage.vue')},
        ]
    }
]

const configsRoutes = [
    {path: '', component: () => import('pages/ConfigsPage.vue')},
];
// Aqui eu preciso ler as configis e montar as rotas de configurações
// Como as configs de um módulo não terá página index, eu vou criar um padrão que vai listar as configs do module
for (let i = 0; i < apps.modules.length; i++) {
    let item = apps.modules[i];
    if (item.key === 'index' || item.active === false || item.visible === false) continue;
    if (item.visible === true || item.visible.apps === true) {
        let indexPage = item.indexPage ? item.indexPage : 'Index';
        let children = [
            {path: '', component: () => import('../modules/' + item.key + '/' + indexPage + '.vue')}
        ];
        if (item.pages && item.pages.length > 0) {
            for (let j = 0; j < item.pages.length; j++) {
                let action = item.pages[j];
                if (action.type === 'separator' || action.type === 'header') continue;
                if (action.pages !== undefined && action.pages.length > 0) {
                    for (let k = 0; k < action.pages.length; k++) {
                        let subAction = action.pages[k];
                        children.push({
                            path: subAction.path,
                            component: () => import('../modules/' + item.key + '/' + subAction.page + '.vue'),
                            meta: {module: item.key, page: subAction.path}
                        });
                    }
                } else {
                    children.push({
                        path: action.path,
                        component: () => import('../modules/' + item.key + '/' + action.page + '.vue'),
                        meta: {module: item.key, page: action.path}
                    });
                }
            }
        }
        routes.push({
            path: '/' + item.key,
            component: () => import('layouts/Module.vue'),
            meta: {module: item.key, page: 'index'},
            children: children
        });
    }
    // Rotas de configurações se existirem no módulo
    if ((item.visible === true || item.visible.configs === true) && item.configs && item.configs.length > 0) {
        /*configsRoutes.push({
            path: item.key,
            component: () => import('pages/ConfigsPage.vue'),
            meta: {module: item.key, page: 'index'}
        });*/
        for (let j = 0; j < item.configs.length; j++) {
            let action = item.configs[j];
            if (action.type === 'separator' || action.type === 'header') continue;
            configsRoutes.push({
                path: item.key + '/' + action.path,
                component: () => import('../modules/' + item.key + '/configs/' + action.page + '.vue'),
                meta: {module: item.key, page: action.path}
            });

        }
    }

}

routes.push({
    path: '/configs',
    meta: {config: true},
    component: () => import('layouts/Module.vue'),
    children: configsRoutes
});
routes.push({
    path: '/usuario',
    component: () => import('layouts/Module.vue'),
    children: [
        {path: 'marca-unidade', component: () => import('../modules/usuario/MarcaUnidade.vue')},
    ]
});
routes.push({
    path: '/acesso',
    component: () => import('layouts/Blank.vue'),
    children: [
        {path: '', component: () => import('pages/LoginPage.vue')},
    ]
});
routes.push({
    path: '/contrato',
    component: () => import('layouts/Blank.vue'),
    children: [
        {path: '', component: () => import('pages/ContratoPage.vue')},
    ]
});


routes.push({
    path: '/:catchAll(.*)*',
    component: () => import('pages/ErrorNotFound.vue')
});
console.log(routes);
export default routes
