import {defineStore} from 'pinia'
import {Amplify} from "boot/amplify";
import { signIn  } from 'aws-amplify/auth';
import {LocalStorage} from 'quasar'
import Notice from "src/tecna/libs/Notice/Notice";
import {useAuthCognitoStore} from "src/tecna/libs/Auth/CognitoAuth";

export const useUserStore = defineStore('user', {
    state: () => ({
        auth: useAuthCognitoStore(),
        token: false,
        userId: 0,
        userName: '',
        userEmail: '',
        marca: {
            id: 0,
            label: 'Não informada'
        },
        unidade: {
            id: 0,
            label: 'Não informada'
        },
    }),
    getters: {
        logado(state) {
            return state.auth.signedIn === true;
        },
        getUserToken(state) {
            return state.auth.token;
        },
        getUserId(state) {
            return state.auth.user.sub
        },
        getUserName(state) {
            return state.auth.user.name
        },
        getUserEmail(state) {
            return state.auth.user.email
        },
        getMarca(state) {
            return state.marca ?? null;
        },
        getMarcaLabel(state) {
            return state.marca.label;
        },
        getMarcaId(state) {
            return state.marca.id ?? null;
        },
        getUnidade(state) {
            return state.unidade ?? null;
        },
        getUnidadeLabel(state) {
            return state.unidade.label;
        },
        getUnidadeId(state) {
            return state.unidade.id ?? null;
        },
    },
    actions: {
        setMarcaUnidade(marca, unidade) {
            this.marca = {
                id: marca.value,
                label: marca.label
            };
            LocalStorage.set('usuario-marca', {
                id: marca.value,
                label: marca.label
            });
            if (unidade.value > 0) {
                this.unidade = {
                    id: unidade.value,
                    label: unidade.label
                };
                LocalStorage.set('usuario-unidade', {
                    id: unidade.value,
                    label: unidade.label
                });
            }
        },
        syncUser() {
            let unidade = LocalStorage.getItem('usuario-unidade');
            if (unidade) {
                this.unidade = unidade;
            }
            let marca = LocalStorage.getItem('usuario-marca');
            if (marca) {
                this.marca = marca;
            }
        },
        reset() {
            this.unidade = {
                id: 0,
                label: 'Não informados'
            };
            this.marca = {
                id: 0,
                label: 'Não informado'
            };
            LocalStorage.remove('usuario-unidade');
            LocalStorage.remove('usuario-marca');
        },
        async logout() {
            await this.auth.logout();
            this.reset();
        }
    }
})
