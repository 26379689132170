import {defineStore} from 'pinia';
import {signIn, signOut, confirmSignIn, resetPassword, confirmResetPassword, updatePassword, fetchAuthSession , fetchUserAttributes } from 'aws-amplify/auth';

export const useAuthCognitoStore = defineStore('auth-cognito', {
    state: () => ({
        token: false,
        signedIn: false,
        errors: [],
        challenge: null,
        user: null,
    }),

    getters: {
    },

    actions: {

        // Login
        async login(username, password) {
            try {
            const {isSignedIn, nextStep} = await signIn({username, password});
            if (isSignedIn) {
                // fez o login
                await this.fetchUser();
                this.signedIn = true;

                return true;
            }
            this.challenge = nextStep.signInStep;
            return false
            } catch (error) {
                this.errors.push(error);
                return false;
            }
        },

        // Confirm Login With New Password
        async confirmLoginNewPass(password) {
            const response = await confirmSignIn({
                challengeResponse: password
            });
            console.log(response);
            if(response.isSignedIn === true && response.nextStep.signInStep === 'DONE') {
                await this.fetchUser();
                this.signedIn = true;
                return true;
            } else {
                return false;
            }
        },

        // Reset Password
        async recoveryPass(username) {
            const response = await resetPassword({
                username: username
            });
            return response.isPasswordReset === false;

        },

        // Confirm Reset Password
        async confirmRecovery(username, code, password) {
            const response = await confirmResetPassword({
                username: username,
                code: code,
                newPassword: password
            });
            console.log('Confirm Recovery', response);
            if(response === 'SUCCESS') {
                return true;
            }
            console.log('Confirm Reset', response);
        },

        // Update Password
        async updatePass(oldPassword, newPassword) {
            const response = await updatePassword({
                oldPassword: oldPassword,
                newPassword: newPassword
            });
            console.log('Update Pass', response);
        },

        // Logout
        async logout() {
            await signOut();
            this.signedIn = false;
            this.user = null;
        },

        // Recupera os dados do usuário

        async fetchUser() {
            const attributes = await fetchUserAttributes();
            const session = await fetchAuthSession();
            this.user = {
                id: attributes.sub,
                name: attributes.name,
                email: attributes.email,
            };
            this.token = session.tokens.accessToken;
            //this.token = session.tokens.idToken;
        }
    }
});
