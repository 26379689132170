/* eslint-disable */
/**
 * THIS FILE IS GENERATED AUTOMATICALLY.
 * DO NOT EDIT.
 *
 * You are probably looking on adding startup/initialization code.
 * Use "quasar new boot <name>" and add it there.
 * One boot file per concern. Then reference the file(s) in quasar.config.js > boot:
 * boot: ['file', ...] // do not add ".js" extension to it.
 *
 * Boot files are your "main.js"
 **/



import {Notify,LocalStorage,BottomSheet,Dialog} from 'quasar'



export default { config: {"notify":{"icon":"sym_o_info","position":"top","progress":true,"actions":[{"icon":"close","color":"white"}]}},plugins: {Notify,LocalStorage,BottomSheet,Dialog} }

