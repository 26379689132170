const apps = {
    modules: [
        {
            key: "agenda",
            visible: true,
            active: true,
            auth: false,
            label: "Agenda",
            group: "operational",
            indexPage: "Agenda",
            icon: "sym_o_calendar_month",
            pages: null
        },
        {
            key: "arquivos",
            group: "operational",
            visible: false,
            active: true,
            auth: false,
            label: "Arquivos",
            icon: "sym_o_cloud_upload",
            pages: [
                {label: "Arquivos comuns", icon: "sym_o_smb_share", path: "comuns", page: "Comuns"},
                {label: "Da unidade", icon: "sym_o_snippet_folder", path: "unidade", page: "Unidade"},
            ]
        },
        {
            key: "avaliacoes",
            group: "franchising",
            visible: false,
            active: true,
            auth: false,
            label: "Avaliações",
            icon: "sym_o_hotel_class",
            pages: null
        },
        {
            key: "clientes",
            group: "franchising",
            visible: true,
            active: true,
            auth: false,
            label: "Clientes",
            indexPage: "Clientes",
            icon: "sym_o_group",
            pages: null
        },

        {
            key: "colaboradores",
            group: "admin",
            visible: true,
            active: true,
            auth: false,
            label: "Usuários",
            icon: "sym_o_person",
            indexPage: "Usuarios",
            pages: null,
            configs: [
                {label: "Times", icon: "sym_o_person", path: "times", page: "Times"},
            ]
        },
        {
            key: "crm",
            group: "marketing",
            visible: false,
            active: false,
            auth: false,
            label: "CRM",
            icon: "sym_o_filter_alt",
            pages: null
        },
        {
            key: "curriculos",
            group: "admin",
            visible: false,
            active: false,
            auth: false,
            label: "Currículos",
            icon: "sym_o_badge",
            pages: null
        },
        {
            key: "estoque",
            group: "franchising",
            visible: false,
            active: true,
            auth: false,
            label: "Estoque",
            icon: "sym_o_inventory",
            indexPage: "Estoque",
            pages: [
                {label: "Kits de produtos", icon: "sym_o_package_2", path: "produtos", page: "Produtos"},
                {label: "Menus", icon: "sym_o_menu_book", path: "menus", page: "Menus"},
                {type: "separator"},
                {type: "header", label: "Compras"},
                {label: "Pedidos de reposição", icon: "sym_o_shopping_cart", path: "pedidos", page: "Pedidos"},
            ]
        },
        {
            key: "produtos",
            group: "franchising",
            active: true,
            auth: false,
            visible: {
                apps: false,
                configs: true
            },
            label: "Produtos",
            icon: "sym_o_inventory",
            indexPage: "Produtos",
            pages: null,
            configs: [
                {label: "Bolos", icon: "sym_o_cake", path: "bolos", page: "Bolos"},
                {label: "Categorias", icon: "sym_o_folder_open", path: "categorias", page: "Categorias"},
                {label: "Kits e produtos", icon: "sym_o_package_2", path: "produtos", page: "Produtos"},
                {label: "Grupos e tipos", icon: "sym_o_ad_group", path: "grupos", page: "Grupos", visible: false},
                {label: "Tipos", icon: "sym_o_ad_group", path: "tipos/:id", page: "Tipos", visible: false},
                {label: "Cardápios", icon: "sym_o_menu_book", path: "cardapios", page: "Cardapios"},
            ]
        },
        {
            key: "festas",
            group: "franchising",
            visible: true,
            active: true,
            auth: false,
            label: "Festas",
            indexPage: "Festas",
            icon: "sym_o_cake",
            pages: [
                {label: "Criar evento", icon: "sym_o_cake", path: "criar/:periodo/:data", page: "CriarFesta", visible: false},
                {label: "Ficha do evento", icon: "sym_o_cake", path: "detalhes/:evento", page: "Evento", visible: false},
            ],
            configs:[
                {label: "Formas de pagamento", icon: "sym_o_attach_money", path: "pagamento", page: "Pagamento"},
                {label: "Temas", icon: "sym_o_person_celebrate", path: "temas", page: "Temas"},
                {label: "Tipos", icon: "sym_o_tv_options_edit_channels", path: "tipos", page: "Tipos"}
            ]
        },
        {
            key: "orcamentos",
            group: "franchising",
            visible: true,
            active: true,
            auth: false,
            label: "Orçamentos",
            indexPage: "Orcamentos",
            icon: "sym_o_request_quote",
            pages: [
                {label: "Criar Orçamento", icon: "sym_o_cake", path: "criar/:periodo/:data", page: "CriarOrcamento", visible: false},
            ]
        },
        {
            key: "descontos",
            group: "franchising",
            visible: true,
            active: true,
            auth: false,
            label: "Descontos",
            indexPage: "Descontos",
            icon: "sym_o_remove_shopping_cart",
            pages: null
        },
        {
            key: "financeiro",
            group: "admin",
            visible: true,
            active: true,
            auth: false,
            label: "Financeiro",
            icon: "sym_o_payments",
            pages: [
                {label: "Festas", icon: "sym_o_cake", path: "festas", page: "Festas" },
                {label: "Reservas", icon: "sym_o_event", path: "reservas", page: "Reservas" },
                {label: "Pedidos franquia", icon: "sym_o_shopping_cart", path: "pedidos", page: "Pedidos", visible: false},
            ]
        },
        {
            key: "indicadores",
            group: "admin",
            visible: false,
            active: false,
            auth: false,
            label: "Indicadores",
            icon: "sym_o_query_stats",
            pages: null
        },
        {
            key: "suporte",
            group: "operational",
            visible: false,
            active: true,
            auth: false,
            label: "Suporte",
            icon: "sym_o_headset_mic",
            pages: [
                {label: "Tickets", icon: "sym_o_contact_mail", path: "tickets", page: "Tickets"}
            ]
        },
        {
            key: "treinamentos",
            group: "operational",
            visible: false,
            active: true,
            auth: false,
            label: "Treinamentos",
            icon: "sym_o_school",
            pages: [
                {label: "Avaliações", icon: "sym_o_fact_check", path: "avaliacoes", page: "Avaliacoes"},
                {label: "Cursos", icon: "sym_o_book", path: "cursos", page: "Cursos"},
                {label: "Inscritos", icon: "sym_o_person", path: "inscritos", page: "Inscritos"},
            ]
        },
        {
            key: "franquias",
            group: "admin",
            visible: {
                apps: false,
                configs: true
            },
            active: true,
            auth: false,
            label: "Franquias",
            icon: "sym_o_store",
            indexPage: "Unidades",
            configs: [
                {label: "Unidades", icon: "sym_o_store", path: "unidades", page: "Unidades"},
                {label: "Franqueados", icon: "sym_o_fact_check", path: "franqueados", page: "Franqueados"},
                {label: "Marcas", icon: "sym_o_brand_awareness", path: "marcas", page: "Marcas"},
            ]
        },
        {
            key: "vendas",
            group: "franchising",
            visible: false,
            active: false,
            auth: false,
            label: "Vendas (PDV)",
            icon: "sym_o_point_of_sale",
            pages: [
                {label: "Pedidos", icon: "sym_o_shopping_cart", path: "pedidos", page: "Pedidos"},
            ]
        },
        {
            key: "logistica",
            group: "operational",
            visible: false,
            active: true,
            auth: false,
            label: "Logística",
            icon: "sym_o_local_shipping",
            indexPage: "Pedidos",
            pages: null
        }
    ],
    groups: [
        {
            key: "marketing",
            visible: false,
            active: true,
            auth: false,
            label: "Marketing",
            icon: "sym_o_calendar_month",
        },
        {
            key: "operational",
            visible: true,
            active: true,
            auth: false,
            label: "Operacional",
            icon: "sym_o_calendar_month",
        },
        {
            key: "franchising",
            visible: true,
            active: true,
            auth: false,
            label: "Franquia",
            icon: "sym_o_calendar_month",
        },
        {
            key: "admin",
            visible: true,
            active: true,
            auth: false,
            label: "Administração",
            icon: "sym_o_settings",
        }
    ]
};

export default apps;
