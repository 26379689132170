import {route} from 'quasar/wrappers'
import {createRouter, createMemoryHistory, createWebHistory, createWebHashHistory} from 'vue-router'
import routes from './routes'
import {useAppStore} from "stores/app";
import {useUserStore} from "stores/user";
/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */

export default route(function (/* { store, ssrContext } */) {
    const createHistory = process.env.SERVER
        ? createMemoryHistory
        : (process.env.VUE_ROUTER_MODE === 'history' ? createWebHistory : createWebHashHistory)

    const Router = createRouter({
        scrollBehavior: () => ({left: 0, top: 0}),
        routes,

        // Leave this as is and make changes in quasar.conf.js instead!
        // quasar.conf.js -> build -> vueRouterMode
        // quasar.conf.js -> build -> publicPath
        history: createHistory(process.env.VUE_ROUTER_BASE)
    });

    Router.beforeEach((to, from, next) => {
        const appStore = useAppStore();
        const userStore = useUserStore();
        userStore.syncUser();

        if(!userStore.logado && to.path !== '/acesso' && to.path !== '/contrato') {
            next('/acesso');
            return;
        }

        let page = to.meta.page ?? null;
        appStore.resetView();
        if(to.meta.config === true) {
            // Abre o módulo de configurações
            appStore.openConfig(to.meta.module, page)
            next();
        } else if(to.meta.module !== undefined) {
            // Abre o módulo e page
            appStore.openModule(to.meta.module, page);
            next();
        } else {
            next();
        }
        //console.log(from.meta.module, '->', to.meta.module, to.meta.page, to.meta.config);

    });

    return Router
})
