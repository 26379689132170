import {defineStore} from 'pinia'
import Apps from "src/router/apps";

export const useAppStore = defineStore('app', {
    state: () => ({
        name: 'Grupo Planeta',
        bucketUrl: 'https://planeta-smart.s3-sa-east-1.amazonaws.com/',
        version: '4.0',
        module: null,
        page: null,
        configMode: false,
        view: {
            title: '',
            subtitle: '',
            icon: '',
            breadcrumb: [],
            menuItens: [],
        }
    }),
    getters: {
        // App
        getName(state) {
            return state.name
        },
        getVersion(state) {
            return state.version
        },
        // Bucket
        getBucketUrl(state) {
            return state.bucketUrl;
        },
        // Module
        getModule(state) {
            return state.module;
        },
        moduleHasMenu(state) {
            return state.view.menuItens.length > 0;
        },
        isConfig(state) {
            return state.configMode;
        },
        // View Page
        getPageTitle(state) {
            return state.view.title;
        },
        getPageSubtitle(state) {
            return state.view.subtitle;
        },
        getPageIcon(state) {
            return state.view.icon;
        },
        getPageBreadcrumb(state) {
            return state.view.breadcrumb;
        },
        getPageMenuItens(state) {
            return state.view.menuItens;
        },

        listModules(state) {
            return (group) => {
                // sort modules by active and label
                return Apps.modules.filter(app => app.group === group && (app.visible === true || app.visible.apps === true)).sort((a, b) => {
                    if (a.active === b.active) {
                        return a.label > b.label ? 1 : -1;
                    }
                    return a.active ? -1 : 1;
                });
            }
        },

        listGroups(state) {
            return Apps.groups.filter(group => group.visible !== false);
        },

        listConfigs(state) {
            return Apps.modules.filter(app => app.configs !== undefined);
        }
    },
    actions: {
        loadModuleDef(module) {
            return Apps.modules.find(app => app.key === module);
        },

        openModule(module, page = null) {
            this.module = module;
            this.configMode = false;
            let def = this.loadModuleDef(module);
            this.view.title = def.label;
            this.view.subtitle = "";
            this.view.icon = def.icon;
            this.view.breadcrumb = [
                {label: def.label, icon: def.icon, path: null}
            ];
            this.view.menuItens = [];
            // find the page in module pages
            this.page = page;
            if(def.pages !== null && def.pages.length > 0) {
                this.view.menuItens = def.pages.filter(act => act.visible !== false);
            }
            if(page) {
                let act = (def.pages !== null) ? def.pages.find(act => act.path === page) : null;
                if(act) {
                    this.view.title = act.label;
                    this.view.subtitle = def.label;
                    this.view.icon = act.icon ?? def.icon;
                }
            }
        },

        openConfig(module, page) {
            this.module = Apps.modules.find(app => app.key === module);
            let def = this.loadModuleDef(module);
            this.configMode = true;
            // find the page in module configs
            this.page = page;
            let act = (def.configs !== null) ? def.configs.find(act => act.path === page) : null;
            this.view.title = act.label;
            this.view.subtitle = def.label;
            this.view.icon = act.icon ?? def.icon;
            this.view.breadcrumb = [
                {label: "Configurações", icon: false, path: "/configs"},
                {label: def.label, path: "/configs"},
                {label: act.label, icon: act.icon, path: null}
            ];
        },

        resetView() {
            this.view.title = '';
            this.view.subtitle = '';
            this.view.icon = '';
            this.view.breadcrumb = [];
            this.view.menuItens = [];
        }
    }
})
